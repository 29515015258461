@import "../variables.scss";

main.sunglasses {
  position: relative;
  padding: 8px;
  height: calc(100vh - 96px);
  aspect-ratio: 1/1;
  flex-grow: 0;

  @media only screen and (max-width: 600px) {
    height: auto;
    .sunglasses-layer {
      display: none;
    }
  }

  .game-container {
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);

    .sunglasses {
      position: absolute;
      left: 0px;
      top: 0px;
      user-select: none;
      opacity: 0;
      cursor: pointer;

      &.show {
        opacity: 1;
      }
    }

    .sunglasses-layer {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .sunglasses {
        width: 55%;
        transition: rotate 150ms;
        rotate: -10deg;
        &:hover {
          rotate: -3deg;
        }
        &.grabbed {
          rotate: 0deg;
        }
      }

      img {
        width: 68%;
      }
    }

    .ascii-layer {
      font-family: "Space Mono";
      line-height: 72%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .sunglasses {
        width: 100%;
      }
    }
  }
}
