@import "../variables.scss";

nav {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  flex-shrink: 0;

  @media only screen and (max-width: 600px) {
    row-gap: 24px;
  }

  * {
    margin: 0;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  a {
    text-decoration: none;
    color: black;

    &.current {
      color: red;
      font-style: italic;
    }
  }

  ul {
    list-style-type: none;
    padding: 12px 0 0 24px;

    display: flex;
    row-gap: 10px;
    flex-direction: column;

    li {
      font-weight: 600;
      font-size: 16px;
      font-family: $body-font;
    }
  }
}
