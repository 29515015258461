@import "./variables.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Minotaur;
  src: url(assets/fonts/MinotaurWeb-Regular.woff2) format("woff2"),
    url(assets/fonts/MinotaurWeb-Regular.woff) format("woff");
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

h1,
h2,
h3 {
  font-family: $display-font;
  margin: 0;
}

.dm-sans-regular {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
