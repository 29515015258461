@import "../variables.scss";

.root {
  display: flex;
  padding: 48px;
  width: 100%;
  min-height: 100vh;
  column-gap: 48px;

  & > * {
    align-items: stretch;
  }
}

@media only screen and (max-width: 600px) {
  .root {
    padding: 24px;
    row-gap: 24px;
    flex-direction: column;
  }
}
