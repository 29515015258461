@import "../variables.scss";

main {
  background-image: $dashed-border;
  display: block;
  flex-grow: 1;
  padding: 48px;
  max-width: 1500px;

  @media only screen and (max-width: 600px) {
    padding: 24px;
  }
}

.content {
  h2 {
    margin-bottom: 32px;
  }

  h3 {
    margin-bottom: 12px;
  }

  p,
  ul {
    margin: 0 0 12px 0;
    font-size: 16px;
    font-family: $body-font;
  }

  img {
    margin: 12px 0;
    max-width: 100%;
  }

  &.about {
    img {
      max-width: 200px;
      margin: 0 8px;
      border-radius: 4px;
      transition: rotate 200ms;

      &:first-child {
        rotate: -1deg;
      }

      &:last-child {
        rotate: 2deg;
      }

      &:hover {
        rotate: 0deg;
      }
    }
  }
}
